<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("resources.services.resources") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @startSearch="submitSearch"
        @removeSelectedFilter="removeSelectedFilter"
        :hasFilters="true"
        :selectedFilters="selectedFilters"
        :routeName="'r_services-resources-search-filters'"
      ></search>
    </template>
    <ul
      class="clebex-item-section pill"
      v-for="(serviceResources, index) in listOfServiceLevels"
      :key="index"
    >
      <span class="order-level">{{ index }}</span
      ><br /><br />
      <li
        class="clebex-item-section-item"
        v-for="serviceResource in serviceResources"
        :key="serviceResource.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="clickAction(serviceResource)"
        >
          <span class="label">
            <span class="highlight">{{ serviceResource.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item" v-if="checked(serviceResource)">
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
    <button type="submit" style="display: none;" ref="submitFormBtn">
      Submit form
    </button>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { defineAsyncComponent } from "vue";

export default {
  name: "GetServiceResources",
  data() {
    return {
      submitFormState: false,
      search: false
    };
  },
  created() {
    this.submitSearch();
  },
  watch: {
    searchActiveNum() {
      this.submitSearch();
    }
  },
  computed: {
    ...mapState("service", ["serviceResources", "serviceResource"]),
    ...mapState("searchFiltersResources", [
      "selectedTypeIds",
      "selectedLevelIds",
      "searchActiveNum"
    ]),
    orderStatus() {
      if (this.serviceOrder && this.serviceOrder.data.service_status) {
        return this.serviceOrder.data.service_status;
      }
      return "";
    },
    listOfServiceLevels() {
      if (this.serviceResources) {
        return this.serviceResources;
      }

      return null;
    },
    searchQuery: {
      get() {
        return this.$store.state.search.searchQuery;
      },
      set(value) {
        this.$store.commit("search/setSearchQuery", value, {
          root: true
        });
      }
    }
  },
  methods: {
    ...mapActions("service", ["getServiceResources", "setServiceResource"]),
    clickAction(serviceResource) {
      this.$router.push({
        name: "r_services-types",
        params: {
          order_resource_id: serviceResource.resource_id
        }
      });
    },
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },
    clearSearchQuery() {
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
      this.submitSearch();
    },
    submitSearch() {
      this.setServiceResource(null);
      this.getServiceResources({
        type_ids: this.selectedTypeIds,
        level_ids: this.selectedLevelIds,
        query: this.searchQuery
      });
    },
    checked(serviceResource) {
      return (
        this.$route.params.order_resource_id == serviceResource.resource_id
      );
    },
    toggleSearch() {
      this.search = !this.search;
      if (!this.search && this.$route.name === "r_resources-search-filters") {
        this.$router.push({ name: "r_resources" });
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  }
};
</script>
<style>
.order-level {
  color: #999999;
  font-size: 0.9375rem;
  line-height: 1.26667em;
  letter-spacing: -0.36px;
  margin-left: 1rem;
  margin-bottom: 5px !important;
}
</style>
